import { createGlobalStyle } from "styled-components";

import titleFont from "../assets/fonts/entsani.ttf";
// import font from '../assets/fonts/opensans/OpenSans-Light.ttf';

const Typography = createGlobalStyle`
  @font-face {
      font-family: entsani;
      font-display: swap;
      src: url(${titleFont});
    }

  * {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-display: swap;
  }

  p, li, span, input, textarea {
    color: var(--black);
  }

  p, li {
    letter-spacing: 0.5px;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
    box-sizing: border-box;
  }

  h1, h2 {
    font-family: entsani;
  }

  h1 {
    color: var(--accent);
    font-size: 1.8em;
    text-shadow: 5px 3px 0px var(--accent-dark);

    @media (min-width: 440px) {
      font-size: 3em;
      text-shadow: 3px 3px 0px var(--accent-dark);
    }

    @media (min-width: 920px) {
      font-size: 5em;
      text-shadow: 4px 4px 0px var(--accent-dark);
    }

  }

  .h1-display {
    font-size: 1.8em;
    color: var(--white);
    padding: 15px;
    text-shadow: 5px 3px 0px var(--accent-dark);

    @media (min-width: 440px) {
      font-size: 4em;
      text-shadow: 5px 5px 0px var(--accent-dark);
    }

    @media (min-width: 920px) {
      font-size: 8em;
       text-shadow: 8px 8px 0px var(--accent-dark);
    }
  }

  h2 {
    font-size: 1.3em;
    padding: 30px 0;

    @media (min-width: 440px) {
      font-size: 1.5em;
    }

    @media (min-width: 920px) {
      font-size: 3em;
    }
  }

  h3.ash2 {
    font-family: entsani;
    font-size: 1.3em;
    padding: 30px 0;

    @media (min-width: 440px) {
      font-size: 1.5em;
    }

    @media (min-width: 920px) {
      font-size: 3em;
    }
  }

  h1 strong,
  h2 strong,
  h3.ash2 strong {
    font-family: entsani;
  }

  h3 {
    font-size: 1.2em;
    color: var(--white);

    @media (min-width: 440px) {
      font-size: 1.5em;
    }
  }

  p, a, li, label, input, input::placeholder {
    font-size: 1.6rem;
    margin: 0;
    line-height: 2.8rem;

    @media(min-width: 1200px) {
      font-size: 2rem;
    }

    @media(min-width: 1200px) {
      font-size: 2rem;
    }

    @media(min-width: 1400px) {
      font-size: 2.2rem;
    }
  }

  a {
    color: var(--main);
    text-decoration: none;
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
    transition: .5s;
    &:hover {
      opacity: 0.6;
    }
  }

  p strong,
  li strong {
    font-weight: normal;
  }

  .font-bold {
    font-weight: bold;
  }


`;

export default Typography;
