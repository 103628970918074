import React from "react";

import FooterStyles from "../styles/FooterStyles";
import logo from "../../static/Stetrics-logo-breed.webp";
import { FaFacebook } from "react-icons/fa";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <FooterStyles>
      <div className='container'>
        <div className='col'>
          <h2>Stetrics</h2>
          <p>Achter de hoven 30</p>
          <p>2222 Itegem</p>
          <a href='mailto:info@stetrics.be'>info@stetrics.be</a>
          <p>BTW: BE 0739.577.488</p>
          <div className='phone'>
            <p>Telefonisch bereikbaar</p>
            <p>Ma - Za: 8u - 21u</p>
            <a href='tel:+32496829085'>+32 (0) 496 82 90 85</a>
          </div>
        </div>
        <div className='col'>
          <nav>
            <h3 className='subtitle'>Navigatie</h3>
            <ul>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/realisaties/'>Realisaties</Link>
              </li>
              <li>
                <Link to='/over/'>Over</Link>
              </li>
              <li>
                <Link to='/contact/'>Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className='col'>
          <div className='footerFlavor'>
            <img src={logo} alt={logo} />
            <p>Jouw partner voor gestroomlijnde elektriciteitswerken</p>
          </div>
          <div className='social'>
            <a className='social-link' href='https://www.facebook.com/Stetrics-103275621210836'>
              <FaFacebook />
            </a>
          </div>
        </div>
      </div>

      <p className='copyright'>©{new Date().getFullYear()} Stetrics - Jouw partner voor gestroomlijnde elektriciteitswerken</p>
    </FooterStyles>
  );
};

export default Footer;
