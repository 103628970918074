import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --main: rgba(51, 51, 51, 1);
    --secondary: rgba(35, 127, 142, 1);
    --accent: #2da4b7;
    --accent-dark: #005261;
    --start-verloop: #237f8e;
    --white: rgba(255, 255, 255, 1);
    --grey: #efefef;
    --danger: rgba(223, 49, 66, 1);
    --success: rgba(66, 169, 72, 1);
    --black: rgba(33, 33, 33, 0.8);
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  body {
    font-size: 2rem;
    background: var(--white);
    color: var(--main);
    position: relative;
  }


  section {
    padding: 30px 0 80px 0;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar,
  textarea::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--green) var(--white);
  }

  body::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar {
    background: var(--white);
  }

  body::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-thumb {
    background-color: var(--accent);
    border-radius: 6px;
  }

  hr {
    border: 0;
    height: 8px;
  }

  img {
    max-width: 100%;
  }

  .Toastify__toast-container {

    @media (min-width: 480px) {
      width: 90%;
    }

    @media (min-width: 960px) {
      width: 60%;
    }
  }

  .stetrics-hr {
    width: 100%;
    background-color: var(--accent);
    height: 2px;
    margin: 10px 0;
  }
 `;

export default GlobalStyles;
