import { Link } from "gatsby";
import React from "react";

import styled from "styled-components";

import logo from "../../static/Stetrics-logo-breed.webp";

const NavbarStyles = styled.nav`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  .navigation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    flex-direction: column;

    @media (min-width: 764px) {
      flex-direction: row;
    }

    @media (min-width: 1130px) {
      width: 1130px;
      justify-content: space-between;
    }
  }

  .logo {
    width: 100px;

    @media (min-width: 400px) {
      width: 200px;
    }

    img {
      max-width: 100px;

      @media (min-width: 400px) {
        max-width: 200px;
      }
    }
  }

  .info {
    display: none;

    @media (min-width: 764px) {
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: right;
    }

    * {
      padding-top: 8px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style-type: none;
    grid-gap: 5px;
    padding: 0;

    li {
      text-align: center;

      a {
        &:hover {
          opacity: 1;
        }

        &:after {
          content: "";
          display: block;
          margin: auto;
          height: 3px;
          width: 0px;
          background: transparent;
          transition: width 0.5s ease, background-color 0.5s ease;
        }

        &:hover:after {
          width: 40%;
          background: var(--accent);
        }
      }
    }
  }

  .active {
    &:after {
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 40%;
      background: var(--accent);
    }
  }
`;

const NavBar = () => {
  return (
    <NavbarStyles id='home'>
      <div className='navigation'>
        <div className='logo'>
          <img src={logo} alt='Logo van Stetrics' />
        </div>
        <ul>
          <li>
            <Link to={"/"} activeClassName={"active"}>
              Home
            </Link>
          </li>
          <li>
            <Link to={"/realisaties/"} activeClassName={"active"}>
              Realisaties
            </Link>
          </li>
          <li>
            <Link to={"/over/"} activeClassName={"active"}>
              Over
            </Link>
          </li>
          <li>
            <Link to={"/contact/"} activeClassName={"active"}>
              Contact
            </Link>
          </li>
        </ul>
        <div className='info'>
          <a href='tel:+32496-829-085'>+32 (0) 496 82 90 85</a>
          <a href='mailto:info@stetrics.be'>info@stetrics.be</a>
        </div>
      </div>
    </NavbarStyles>
  );
};

export default NavBar;
