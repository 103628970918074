import styled from "styled-components";

const FooterStyles = styled.footer`
  background: var(--secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 15px 0 15px;

  .container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: center;
    grid-gap: 15px;

    @media (min-width: 550px) {
      grid-template-columns: 1fr 1fr;
      width: 80%;
    }

    @media (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      width: 80%;
    }

    @media (min-width: 1200px) {
      width: 60%;
    }

    .col {
      display: flex;
      flex-direction: column;
      width: 60%;
      height: 100%;
      flex: 1 0 0px;
      margin-bottom: 40px;

      img {
        width: 80%;
        padding: 5px 0;
      }

      ul {
        margin: 5px 0 5px 0;
      }

      .phone {
        padding-top: 15px;
      }

      nav {
        ul {
          padding-top: 8px;
        }
      }

      @media (min-width: 550px) {
        width: 100%;
        &:last-child {
          grid-column: 1 / span 2;
        }
      }

      @media (min-width: 992px) {
        &:last-child {
          grid-column: 3;
        }
      }

      .social {
        display: flex;
        flex-direction: row;
        padding-top: 15px;

        @media (min-width: 992px) {
          padding-bottom: 15px;
        }

        .social-link {
          display: block;
          margin: 0;
          width: 40px;
          height: 40px;

          svg {
            width: 100%;
            height: 100%;
            padding: 0;
          }
        }
      }

      @media (min-width: 992px) {
        margin: 0;
      }
    }
  }

  h2,
  h4 {
    color: var(--white);
    font-weight: 500;
    margin: 0 0 5px 0;
    text-align: left;
    padding: 0;
    font-size: 1.2em;

    @media (min-width: 440px) {
      font-size: 1.5em;
    }
  }

  p,
  a,
  li {
    color: var(--white);
  }

  .copyright {
    font-size: 0.8em;
    color: var(--white);
    padding: 15px 0;
    text-align: center;
  }

  .footerFlavor {
    width: 100%;
  }
`;

export default FooterStyles;
