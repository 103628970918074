import React from 'react';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import NavBar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <NavBar />
      <>{children}</>
      <Footer />
    </>
  );
};

export default Layout;
